interface IUseQueryString {
  subscriptionId: string;
  subscriptionConfigurationToken: string;
  personId: string;
  platform: string;
}

export const useGetQueryString = (): IUseQueryString => {
  const urlParams = new URLSearchParams(window.location.search);

  return {
    subscriptionId: urlParams.get("subscription_id") || "",
    subscriptionConfigurationToken:
      urlParams.get("subscription_configuration_token") || "",
    personId: urlParams.get("person_id") || "",
    platform: urlParams.get("platform") || "",
  };
};
