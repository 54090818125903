import { IMessageSet } from "../hooks/useGetAllMessageSets";
import { ISubscription } from "../../backend/types";
import { ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Spacer,
  Medium,
  Padding,
  Body,
  Icon,
  Button,
  InstallableContainer,
} from "@vestaboard/installables";

interface IMessageSets {
  messageSets: IMessageSet[];
  subscription: ISubscription;
  updateSubscription: (subscription: ISubscription) => void;
  platform: string;
  setTab: (tab: number) => void;
}

const useStyles = makeStyles({
  iconAndTextContainer: {
    display: "flex",
  },
  bold: {
    color: "#DDD",
  },
  icon: {
    height: 16,
    width: 16,
  },
  messageSetContainer: {
    background: "#393a3e",
    borderRadius: 12,
  },
  messageSet: {
    display: "flex",
    color: "#FFF",
    borderBottom: "1px solid #4a4c50",
    padding: 10,
    paddingRight: 24,
    paddingLeft: 24,
    alignItems: "center",
    justifyContent: "space-between",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  messageSetLeft: {
    display: "flex",
    alignItems: "center",
  },
  messageSetIcon: {
    height: 40,
    width: 40,
    marginRight: 32,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 7,
  },
  discoverLink: {
    textDecoration: "none",
  },
  bottomButtons: {
    position: "fixed",
    bottom: 0,
    width: "100vw",
    left: 0,
    background: "rgba(36, 40, 41, 0.9)",
  },
});

export const MessageSets = (props: IMessageSets) => {
  const classes = useStyles();
  const messageSets = props.messageSets
    .filter((messageSet) =>
      (props.subscription?.messageSetIds || []).includes(messageSet.id)
    )
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <>
      <Padding>
        <Spacer size="medium" />
        <Medium>
          Blend a variety of topics you like and have Vestaboard surprise you on
          a frequency of your choosing.
        </Medium>
        <Spacer size="medium" />
        <div className={classes.iconAndTextContainer}>
          <Medium>
            To add more content to your Blended channel, click{" "}
            <strong className={classes.bold}>Blend</strong> while discovering
            other channels within Vestaboard+.
          </Medium>
        </div>
        <Spacer size="extraLarge" />
        <div className={classes.messageSetContainer}>
          {messageSets.map((messageSet) => (
            <div key={messageSet.id} className={classes.messageSet}>
              <div className={classes.messageSetLeft}>
                <img
                  src={messageSet.icon || ""}
                  alt={messageSet.title || ""}
                  className={classes.messageSetIcon}
                />
                <Body>{messageSet.title}</Body>
              </div>
              <ButtonBase
                onClick={() => {
                  props.updateSubscription({
                    ...props.subscription,
                    messageSetIds: (
                      props.subscription?.messageSetIds || []
                    ).filter((id) => id !== messageSet.id),
                  });
                }}
              >
                <Icon type="delete" />
              </ButtonBase>
            </div>
          ))}
        </div>
        <Spacer size="extraLarge" />
        <Spacer size="extraLarge" />
        {props.subscription.setupComplete ? null : (
          <>
            <Spacer size="extraLarge" />
            <Spacer size="extraLarge" />
          </>
        )}
      </Padding>
      <div className={classes.bottomButtons}>
        <InstallableContainer>
          <Padding>
            <a
              href={`https://platform.vestaboard.com/deeplink/plus/discover?platform=${props.platform}`}
              target={props.platform === "web" ? "_parent" : "_blank"}
              rel="noreferrer"
              className={classes.discoverLink}
            >
              <Button onClick={() => {}} buttonType="outline">
                Discover More Channels
              </Button>
            </a>
            {props.subscription.setupComplete ? null : (
              <>
                <Spacer size="medium" />
                <Button
                  onClick={() => {
                    props.setTab(1);
                  }}
                  buttonType="white"
                >
                  Complete Setup
                </Button>
              </>
            )}
          </Padding>
        </InstallableContainer>
      </div>
    </>
  );
};
