import { useEffect, useState } from "react";
import { PLATFORM_ENDPOINT } from "../constants";

export interface IMessageSet {
  id: string;
  title: string;
  description: string | null;
  icon: string | null;
}

export const useGetAllMessageSets = () => {
  const [data, setData] = useState<IMessageSet[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(`${PLATFORM_ENDPOINT}/v3.0/message-sets`)
      .then((res) => res.json())
      .then((data) => {
        setData(data?.messageSets as IMessageSet[]);
        setLoading(false);
      });
  }, []);

  return {
    data,
    loading,
  };
};
