import {
  CadencePicker,
  DayOfWeek,
  Interval,
  Medium,
  Padding,
  Spacer,
  parseTimeString,
  Button,
  useToasts,
} from "@vestaboard/installables";
import { ISubscription } from "../../backend/types";
import { CircularProgress } from "@mui/material";
import {  makeStyles } from "@mui/styles";
import { useState } from "react";
import { useGetMarketplaceListing } from "../hooks/useGetMarketplaceListing";

interface ISettings {
  subscription: ISubscription;
  subscriptionConfigurationToken: string;
  updateSubscription: (subscription: ISubscription) => void;
}

const useStyles = makeStyles({
  line: {
    height: 1,
    width: "100%",
    background: "#333",
  },
});

export const Settings = (props: ISettings) => {
  const classes = useStyles();
  const [subscription, setSubscription] = useState(props.subscription);
  const marketplaceListing = useGetMarketplaceListing({
    subscriptionConfigurationToken: props.subscriptionConfigurationToken,
  });
  const { addToast } = useToasts();

  console.log(marketplaceListing);

  if (!marketplaceListing.data) {
    return <CircularProgress />;
  }

  return (
    <Padding>
      <Spacer size="medium" />
      <Medium>
        Be surprised with a new message from your Blended content at the
        following frequency.
      </Medium>
      <Spacer size="large" />
      <div className={classes.line} />
      <Spacer size="large" />
      <CadencePicker
        allowWeekdaySelection
        defaultDays={[
          DayOfWeek.Monday,
          DayOfWeek.Tuesday,
          DayOfWeek.Wednesday,
          DayOfWeek.Thursday,
          DayOfWeek.Friday,
          DayOfWeek.Saturday,
          DayOfWeek.Sunday,
        ]}
        intervals={marketplaceListing.data.validIntervals}
        cadence={
          subscription.cadence || {
            interval: Interval.Hour,
            startAtTime: parseTimeString("09:00"),
            endAtTime: parseTimeString("17:00"),
            daysOfTheWeek: [
              DayOfWeek.Monday,
              DayOfWeek.Tuesday,
              DayOfWeek.Wednesday,
              DayOfWeek.Thursday,
              DayOfWeek.Friday,
            ],
          }
        }
        onChange={(cadence) => {
          setSubscription({
            ...subscription,
            cadence,
          });
        }}
      />
      <Spacer size="extraLarge" />
      <Button
        onClick={async () => {
          await props.updateSubscription(subscription);
          addToast("Your settings were saved successfully", {
            appearance: "success",
          });
        }}
        buttonType="white"
      >
        Save
      </Button>
      <Spacer size="extraLarge" />
    </Padding>
  );
};
