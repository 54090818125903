import { useCallback } from "react";
import { API_ENDPOINT } from "../constants";

export interface IUseDisplayNow {
  subscriptionId: string;
  subscriptionConfigurationToken: string;
}

export const useDisplayNow = () => {
  const mutation = useCallback(async (input: IUseDisplayNow) => {
    await fetch(
      `${API_ENDPOINT}/api/subscriptions/${input.subscriptionId}/display-now`,
      {
        method: "POST",
        headers: {
          "x-vestaboard-subscription-configuration-token":
            input.subscriptionConfigurationToken,
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      }
    ).then((res) => res.json());
  }, []);

  return [mutation];
};
