import { useEffect, useState } from "react";
import { IMarketplaceListing } from "../../backend/types";
import { API_ENDPOINT } from "../constants";

export interface IUseGetMarketplaceListing {
  subscriptionConfigurationToken: string;
}

export const useGetMarketplaceListing = (input: IUseGetMarketplaceListing) => {
  const [data, setData] = useState<IMarketplaceListing | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(`${API_ENDPOINT}/api/marketplace-listing`, {
      headers: {
        "x-vestaboard-subscription-configuration-token":
          input.subscriptionConfigurationToken,
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(setData)
      .then(() => setLoading(false));
  }, [input.subscriptionConfigurationToken]);

  return {
    data,
    loading,
  };
};
