import {
  InstallableContainer,
  Theme,
  ToastProvider,
} from "@vestaboard/installables";
import { TabsArea } from "./components/TabsArea";

export const App = () => (
  <Theme>
    <ToastProvider offset={65}>
      <InstallableContainer>
        <TabsArea />
      </InstallableContainer>
    </ToastProvider>
  </Theme>
);
