import { ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

interface IActionButton {
  children: React.ReactNode;
  padding?: string;
  onClick: () => void;
}

const useStyles = makeStyles({
  button: {
    backgroundColor: "#FFF",
    color: "#000",
    borderRadius: 7,
    padding: "8px 14px",
    fontSize: 14,
  },
});

export const ActionButton = (props: IActionButton) => {
  const classes = useStyles();
  return (
    <ButtonBase onClick={props.onClick}>
      <div
        className={classes.button}
        style={{
          padding: props.padding || "8px 14px",
        }}
      >
        {props.children}
      </div>
    </ButtonBase>
  );
};
