import { useCallback, useEffect, useState } from "react";
import { ISubscription } from "../../backend/types";
import { API_ENDPOINT } from "../constants";

export interface IUseGetSubscription {
  subscriptionId: string;
  subscriptionConfigurationToken: string;
}

export const useGetSubscription = (input: IUseGetSubscription) => {
  const [data, setData] = useState<ISubscription | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(`${API_ENDPOINT}/api/subscriptions/${input.subscriptionId}`, {
      headers: {
        "x-vestaboard-subscription-configuration-token":
          input.subscriptionConfigurationToken,
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(setData)
      .then(() => setLoading(false));
  }, [input.subscriptionConfigurationToken, input.subscriptionId]);

  const updateSubscription = useCallback(
    async (subscription: ISubscription) => {
      setData(subscription);

      await fetch(`${API_ENDPOINT}/api/subscriptions/${input.subscriptionId}`, {
        method: "POST",
        headers: {
          "x-vestaboard-subscription-configuration-token":
            input.subscriptionConfigurationToken,
          "content-type": "application/json",
        },
        body: JSON.stringify(subscription),
      })
        .then((res) => res.json())
        .then(() => setLoading(false));
    },
    [input.subscriptionConfigurationToken, input.subscriptionId]
  );

  return {
    data,
    loading,
    updateSubscription,
  };
};
