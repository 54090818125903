import {
  NotificationBanner,
  Padding,
  Spacer,
  TabBar,
  useToasts,
} from "@vestaboard/installables";
import { useState } from "react";
import { useGetAllMessageSets } from "../hooks/useGetAllMessageSets";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useGetQueryString } from "../hooks/useGetQueryString";
import { useGetSubscription } from "../hooks/useGetSubscription";
import { MessageSets } from "./MessageSets";
import { Settings } from "./Settings";
import { ActionButton } from "./ActionButton";
import { useDisplayNow } from "../hooks/useDisplayNow";
import { fetchQuietHours, useGetQuietHours } from "../hooks/useGetQuietHours";

const useStyles = makeStyles({
  line: {
    background: "#333",
    height: 1,
    width: "100%",
    position: "relative",
    top: -1,
    zIndex: -1,
  },
  tabBarContainer: {
    "& button": {
      fontSize: "16px !important",
      marginBottom: 10,
      marginTop: 10,
      marginLeft: 8,
      marginRight: 8,
      color: "#FFF !important",
    },
    position: "relative",
  },
  displayNow: {
    position: "absolute",
    bottom: 12,
    right: 12,
  },
});

export const TabsArea = () => {
  const classes = useStyles();
  const { data: allMessageSets, loading: loadingAllMessageSets } =
    useGetAllMessageSets();
  const queryString = useGetQueryString();
  const { data, loading, updateSubscription } = useGetSubscription({
    subscriptionId: queryString.subscriptionId,
    subscriptionConfigurationToken: queryString.subscriptionConfigurationToken,
  });
  const { data: quietHoursData } = useGetQuietHours({
    subscriptionConfigurationToken: queryString.subscriptionConfigurationToken,
  });
  const [displayNow] = useDisplayNow();
  const [tab, setTab] = useState(0);
  const { addToast } = useToasts();
  const [disableDisplayNow, setDisableDisplayNow] = useState(false);

  return (
    <>
      <div className={classes.tabBarContainer}>
        {quietHoursData?.isQuietHours ? <Spacer size="extraLarge" /> : null}
        <div
          style={{
            maxWidth: 400,
          }}
        >
          <NotificationBanner
            visible={quietHoursData?.isQuietHours || false}
            text={quietHoursData?.formattedQuietHours || ""}
          />
        </div>
        <TabBar
          tabs={[
            {
              key: "content",
              label: "Content",
            },
            {
              key: "frequency",
              label: "Frequency",
            },
          ]}
          disableRipple={false}
          fullWidth={false}
          value={tab}
          setValue={(value) => {
            setTab(value);
          }}
        />
        <div className={classes.displayNow}>
          <ActionButton
            onClick={async () => {
              if (!!disableDisplayNow) {
                return;
              }

              setDisableDisplayNow(true);

              const quietHours = await fetchQuietHours(
                queryString.subscriptionConfigurationToken
              );

              if (quietHours?.isQuietHours) {
                addToast(quietHours?.formattedQuietHours, {
                  appearance: "error",
                });
                setDisableDisplayNow(false);
                return;
              }

              await displayNow({
                subscriptionId: queryString.subscriptionId,
                subscriptionConfigurationToken:
                  queryString.subscriptionConfigurationToken,
              })
                .then(() => {
                  addToast("Message sent to your Vestaboard", {
                    appearance: "success",
                  });
                })
                .catch(() => {
                  addToast("Message failed to send to your Vestaboard", {
                    appearance: "error",
                  });
                });

              setDisableDisplayNow(false);
            }}
          >
            Display Now
          </ActionButton>
        </div>
      </div>
      <div className={classes.line} />
      {loadingAllMessageSets || loading || !data ? (
        <Padding>
          <Spacer size="medium" />
          <CircularProgress />
        </Padding>
      ) : tab === 0 ? (
        <MessageSets
          messageSets={allMessageSets}
          subscription={data}
          updateSubscription={updateSubscription}
          platform={queryString.platform}
          setTab={setTab}
        />
      ) : (
        <Settings
          subscription={data}
          updateSubscription={updateSubscription}
          subscriptionConfigurationToken={
            queryString.subscriptionConfigurationToken
          }
        />
      )}
    </>
  );
};
