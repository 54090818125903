import { useEffect, useState } from "react";
import { API_ENDPOINT } from "../constants";

interface IQuietHours {
  quietHoursBegin: string;
  quietHoursEnd: string;
  isQuietHours: boolean;
  timezone: string;
  formattedQuietHours: string;
}

interface IUseGetQuietHours {
  subscriptionConfigurationToken: string;
}

export const fetchQuietHours = async (
  subscriptionConfigurationToken: string
): Promise<IQuietHours> => {
  return fetch(`${API_ENDPOINT}/api/quiet-hours`, {
    headers: {
      "x-vestaboard-subscription-configuration-token":
        subscriptionConfigurationToken,
      "content-type": "application/json",
    },
  }).then((res) => res.json() as unknown as IQuietHours);
};

export const useGetQuietHours = (input: IUseGetQuietHours) => {
  const [data, setData] = useState<IQuietHours | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchQuietHours(input.subscriptionConfigurationToken)
      .then(setData)
      .then(() => setLoading(false));
  }, [input.subscriptionConfigurationToken]);

  return {
    data,
    loading,
  };
};
